import "./Footer.css";
import React from "react";

export default function Footer() {
  return (
    <footer className="footer-wrapper">
        <div className="footer-details-container">
          <div className="footer-item">
            <h3>Coloursk</h3>
            <p>About</p>
            <p>Policy</p>
          </div>
          <div className="footer-item">
            <h3>Category</h3>
            <p>Backcases</p>
            <p>Mug printing</p>
            <p>T shirt</p>
            <p>Pillow priniting</p>
          </div>
          <div className="footer-item">
            <h3>Address</h3>
              <p># 1239, BTM Layout 4th Stage,</p>
              <p>2nd Block, Near RTO Office</p>
              <p> Bangalore - 560076</p>
              <p>
                <a href="tel:8050877778">+91 8050877778</a>
              </p>
          </div>
          <div className="footer-item">
            <h3>User Info</h3>
              <p>Account</p>
          </div>
          <div className="footer-item">
            <h3>Follow us :</h3>
            <div>
              <a
                href="https://www.facebook.com/pages/category/Company/coloursk-2060550150849157/"
                target="_blank"
                className="facebook"
                rel="noreferrer"
              >
                <img height="24" src="/icons/facebook.png" width="24" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/_coloursk/" target="_blank" rel="noreferrer">
                <img height="24" src="/icons/instagram.png" width="24" alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="footer-copyrights">
          Copyright © All rights reserved by www.coloursk.com
        </div>
    </footer>
  );
}
