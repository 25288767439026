import React from 'react'
import './Header.css';

export default function Header() {
  return (
    <header className="header" >
        <img className="header-img" src="logo.png" alt="ColoursK" />
    </header>
  )
}
