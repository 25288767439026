import React from "react";
import "./Body.css";
export default function Body() {
  return (
    <div className="main-body">
      <a className="banner-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
        <img
          className="banner-img"
          src="/new_page/main-banner.jpg"
          alt="ColoursK"
        />
      </a>
      <div className="items-list">
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-1.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-2.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-3.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-4.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-5.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-6.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-7.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-8.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-9.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-10.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-11.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
        <a className="mobile-item-wrapper" href="https://wa.me/919164064037?text=Can%20you%20share%20more%20mobile%20panels">
          <img
            src="new_page/item-12.jpg"
            alt="Mobiles"
            className="mobile-item"
          />
        </a>
     </div>   
    </div>
  );
}
