import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
function App() {
  return <>
    <Header></Header>
    <Body></Body>
    <Footer></Footer>
  </>;
}

export default App;